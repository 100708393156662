import * as React from "react";

import Layout from "../components/Base/Layout";
import SignInPage from "../components/Login/sign-in-page";
import { SEO } from "../components/Base/Seo";
import UnAuthContent from "../components/Login/UnAuthContent";

const LogIn = () => {
  return (
    <Layout>
      <UnAuthContent>
        <SignInPage />
      </UnAuthContent>
    </Layout>
  );
}

export default LogIn

export const Head = () => (
  <SEO title="Canadian Supply | Login " description="" />
)